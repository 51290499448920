<template>
	<main-tabs :links="links">
		<router-view></router-view>
	</main-tabs>
</template>

<script>
	export default {
		data() {
			return {
				links: [{
					to: {
						path: '/fb-accounts/info'
					},
					label: '账户信息'
				}]
			}
		}
	}
</script>

